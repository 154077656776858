import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/flayout"
import Blog from "../components/blog"

export default class BlogList extends React.Component {

  handlePageChange = (pageNumber) => {
    
       navigate('/blog/'+(pageNumber===1 ? "" : pageNumber),{
        state: { scroll: true },
      })
    
  }
  

  render() {
    const posts = this.props.data.allWordpressPost.edges;
    const tags = this.props.data.allWordpressCategory.edges;
    const index = this.props.pageContext.index;
    const numPages = this.props.pageContext.numPages;
    const postsPerPage = 10;
    return (
      <Layout darkHeader={true} seoFields={seoFields}>
      	<Blog posts={posts} tags={tags} index={index} numPages={numPages} handlePageChange={this.handlePageChange} perPage={postsPerPage} showPagination={true} location={this.props.location} />
      </Layout>
    )
  }
}

const seoFields = {
  description: "Get the latest updates from MProfit. Learn what you need to do to manage your investments better for making sound investment decisions. Read about the latest changes in Indian financial markets that concern investors and learn how you should adapt to these changes - examples include Grandfathering of Capital Gains, Bond Indexation, GST etc.",
  // lang,
  // meta,
  // keywords,
  title: "Blog" 
}


export const blogTagListQuery = graphql`
  query blogTagListQuery($skip: Int!, $limit: Int!) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          excerpt
          slug
          content
          date
          path
	      featured_media{ 
	        localFile {
	            childImageSharp {
	              fluid(maxWidth: 220) {
	                ...GatsbyImageSharpFluid_noBase64
	              }
	            }
	          }
	       }
        }
      }
    }
    allWordpressCategory(filter: {count:{gt:0}})
      {
        edges {
          node {
            id
            name
            path
          }
        }
      }
  }
`